/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { AnalyticsContext } from '../../../context'

interface Props {
  readonly title?: string
  readonly children: React.ReactNode
}

const LayoutBlank: React.FC<Props> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query BlankPageTypesQuery {
      allContentfulPage {
        edges {
          node {
            gtmPageType
            seoTitle
            slug
          }
        }
      }
    }
  `)

  const allPageTypes = data.allContentfulPage.edges

  const analytics = useContext(AnalyticsContext)

  useEffect(() => {
    analytics.dispatch({
      type: 'allPageTypes',
      payload: allPageTypes,
    })
  }, [allPageTypes])

  return (
    <>
      <main id="main">{children}</main>
    </>
  )
}

export default LayoutBlank
