import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { EmailSignatureGeneratorPageQuery } from '../../types/graphql-types'
import ContentfulRichText from '../components/ContentfulRichText'
import { ContentWrapper } from '../styles/email-signature-styles'
import LayoutBlank from '../components/Layout/Blank'
interface Props {
  data: EmailSignatureGeneratorPageQuery
}

export const query = graphql`
  # Write your query or mutation here
  query EmailSignatureGeneratorPage {
    contentfulPage(externalName: { eq: "Email Signature Generator Page" }) {
      seoTitle
      seoDescription
      noindex
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            description
            file {
              url
            }
          }
          ... on ContentfulCustomBlock {
            __typename
            id
            contentful_id
            classes
            content {
              raw
              references {
                ... on ContentfulAsset {
                  __typename
                  contentful_id
                  description
                  file {
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulGatsbyComponent {
            __typename
            contentful_id
            id
            name
            references {
              ... on ContentfulList {
                __typename
                externalName
                contentful_id
                id
                name
                items {
                  ... on ContentfulText {
                    __typename
                    externalName
                    contentful_id
                    shortSimpleText
                    richText {
                      raw
                      references {
                        ... on ContentfulAsset {
                          __typename
                          contentful_id
                          file {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function EmailSignatureGeneratorPage({ data }: Props): JSX.Element {
  return (
    <LayoutBlank>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        noIndex={data.contentfulPage?.noindex as boolean}
      />
      <ContentWrapper>
        <ContentfulRichText document={data.contentfulPage?.content} />
      </ContentWrapper>
    </LayoutBlank>
  )
}

export default EmailSignatureGeneratorPage
